import { Button } from "@mui/material";
import flyerPdf from "../assets/240301_stadtlüüd.pdf";
import flyer from "../assets/240301_stadtlüüd.png";
import foerderrichtlinienPdf from "../assets/ifb_förderrichtlinien.pdf";
import foerderrichtlinien from "../assets/ifb_förderrichtlinien.png";
import uebersichtsplanPdf from "../assets/übersichtsplan.pdf";
import uebersichtsplan from "../assets/übersichtsplan.png";
import TextBlockEven from "./TextBlockEven";

const Downloads = () => {
  const itemData = [
    {
      img: flyer,
      file: flyerPdf,
      fileName: "240301_stadtlüüd.pdf",
      title: "Flyer",
    },
    // {
    //   img: funktionsplan,
    //   file: funktionsplanPdf,
    //   fileName: "Funktionsplan.pdf",
    //   title: "Funktionsplan",
    // },
    {
      img: uebersichtsplan,
      file: uebersichtsplanPdf,
      fileName: "Übersichtsplan.pdf",
      title: "Übersichtsplan",
    },
    {
      img: foerderrichtlinien,
      file: foerderrichtlinienPdf,
      fileName: "IFB_Wohnraum_04_Baugemeinschaften_20240506_2024_v08_BF_PAC3.cleaned.pdf",
      title: "IFB Förderrichtlinien",
    },
  ];

  const text = (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      {itemData.map((item) => (
        <div key={item.img} className="flex flex-col items-center justify-center">
          <Button component="a" href={item.file} download={item.fileName} target="_blank" className="w-full">
            <img src={item.img} alt={item.title} />
          </Button>
          <div className="mt-2 text-center">
            <span className="h-full block text-sm font-medium">{item.title}</span>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <section className="downloads">
      <TextBlockEven titlePrimary="Zum Mitnehmen" titleSecondary="Dokumente und Informationen" text={text} />
    </section>
  );
};

export default Downloads;
