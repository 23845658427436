import { TypeAnimation } from "react-type-animation";
import house from "../assets/hauslueued.png";
import Hamburger from "./Hamburger";
import ThemeSwitcher from "./ThemeSwitcher";

const Header = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen text-center dark:bg-slate-800 text-black dark:text-white">
      <div className="absolute top-4 right-4">
        <ThemeSwitcher />
      </div>
      <div className="absolute top-4 left-4 flex items-center">
        <Hamburger />
      </div>

      <h1 className="font-semibold text-2xl sm:text-3xl lg:text-4xl sm:pt-1 font-abc-gravity dark:font-minion-pro">
        <TypeAnimation
          sequence={["Wilkommen bei den STADTLÜÜD!"]}
          wrapper="span"
          speed={30}
          repeat={0}
          cursor={false}
        />
      </h1>
      <div className="text-base sm:text-lg lg:text-xl font-abc-gravity font-normal dark:font-minion-pro">
        <TypeAnimation
          sequence={[
            "Wir suchen noch Mitstreiter:innen für unser Projekt!",
            1000,
            "Interessiert?",
            1000,
            "Dann schreib uns!",
            1000,
          ]}
          wrapper="span"
          speed={50}
          repeat={Infinity}
        />
      </div>
      <a
        href={`mailto:info@stadtlueued.de`}
        className="bg-black hover:bg-gray-800 font-abc-gravity dark:bg-white dark:hover:bg-gray-200 text-white dark:text-black mb-10 p-3 lg:px-6 lg:py-3  mt-4 lg:m-8 text-sm sm:text-base lg:text-lg rounded inline-block"
        title="mailto:info@stadtlueued.de"
      >
        Contact us
      </a>
      <img className="max-w-full sm:max-w-xl lg:max-w-2xl p-2 sm:pt-3 lg:pt-4" alt="hero" src={house} />
    </div>
  );
};

export default Header;
