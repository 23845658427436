import { Helmet } from "react-helmet";
import About from "../components/About";
import CommonRooms from "../components/CommonRooms";
import Contact from "../components/Contact";
import Downloads from "../components/Downloads";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Motivation from "../components/Motivation";

const MainScreen = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Stadtlüüd - Baugemeinschaft</title>
        <meta
          name="description"
          content="Baugemeinschaft Stadtlüüd - Gemeinsam wohnen und leben südlich der Elbe. Erfahre mehr über unser Projekt, unsere Gemeinschaft und unsere Werte."
        />
        <meta
          name="keywords"
          content="Baugemeinschaft, Baugenossenschaft, Stadtlüüd, Hamburg, Gemeinschaft, Wohnen, Wilhelmsburg, Neubau, Mietwohnungen, Genossenschaft"
        />
        {/* Open Graph meta tags */}
        <meta property="og:title" content="Stadtlüüd - Baugemeinschaft" />
        <meta
          property="og:description"
          content="Baugemeinschaft Stadtlüüd - Gemeinsam wohnen und leben südlich der Elbe."
        />
        <meta property="og:url" content="www.stadtlueued.de" />
      </Helmet>
      <Header />
      <About />
      <Motivation />
      <CommonRooms />
      <Downloads />
      <Contact />
      <Footer />
    </div>
  );
};

export default MainScreen;
